import React from 'react';
import { useEffect, useState } from 'react';
import { GridTopPage, IconTituloStato, ButtonStato } from '../../../commons/styleds';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { IConsultor, IContato, ICRM } from '../../../commons/genericTypes';
import { DataGrid, GridCellParams, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { ptBR } from '@mui/material/locale';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import { history } from '../../../helpers/history';
import { useContatoActions } from '../../../states/crm/contatos/contato.actions';
import { useConsultorActions } from '../../../states/config/CadastroUsuarios/consultores/consultor.actions';

const Contatos: React.FC = () => {
  const contatoActions = useContatoActions();
  const [contatos, setContatos] = useState<IContato[]>([]);
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const consultorActions = useConsultorActions();
  const [consultorOnboardings, setConsultorOnboardings] = useState<IConsultor[]>([]);

  const buscaInicial = () => {
    const searchParams = new URLSearchParams(search);
    const textoBusca = searchParams.get('busca');
    contatoActions
      .list(textoBusca, false)
      .then((resp) => {
        setContatos(resp);
      })
      .catch((e) => toast.warn(e))
      .finally(() => setLoading(false));

    consultorActions
      .list()
      .then((resp: any) => {
        setConsultorOnboardings(resp.resultado);
      })
      .catch((err: any) => toast.warn(err));
  };

  useEffect(() => {
    setLoading(true);
    buscaInicial();
  }, [search]);

  const deletarCRM = async (data: ICRM) => {
    setLoading(true);
    try {
      await contatoActions.delete(data.id ?? 0);
      toast.success('Contato deletado');
      await buscaInicial();
    } catch (error) {
      console.error(error);
      toast.error('Erro ao deletar contato!');
    }
    setLoading(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'empresa',
      headerName: 'Empresa',
      maxWidth: 195,
      flex: 0,
    },
    {
      field: 'nome',
      headerName: 'Nome',
      maxWidth: 165,
      minWidth: 120,
      flex: 0.5,
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: 130,
    },
    {
      field: 'email',
      headerName: 'E-mail Principal',
      width: 180,
    },
    {
      field: 'modeloComercial',
      headerName: 'Modelo Comercial',
      width: 120,
    },
    {
      field: 'bUs',
      headerName: 'BU',
      width: 120,
    },
    {
      field: 'dataInicio',
      headerName: 'Data Início',
      width: 120,
      renderCell: (params: GridCellParams) => {
        let crm = params.row as ICRM;
        return crm.dataInicio ? new Date(crm.dataInicio.toString()).toLocaleDateString() : "";
      },
    },
    {
      field: 'dataFim',
      headerName: 'Data Término',
      width: 120,
      renderCell: (params: GridCellParams) => {
        let crm = params.row as ICRM;
        return crm.dataFim ? new Date(crm.dataFim.toString()).toLocaleDateString() : "";
      },
    },
    {
      field: 'plano',
      headerName: 'Plano (Programa)',
      width: 160,
    },
    {
      field: 'idConsultorMaster',
      headerName: 'Consultor',
      width: 120,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ICRM;
        return (
          consultorOnboardings?.find((f) => f.id === obj.idConsultorMaster)?.nome
        )
      },
    },
    {
      field: '',
      headerName: 'Ações',
      maxWidth: 100,
      flex: 0.5,
      editable: false,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params: GridCellParams) => {
        let crm = params.row as ICRM;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                paddingRight: '8px',
                color: 'rgb(70, 70, 70)',
              }}
              to={'/crm/contatos/' + crm.id}
              onClick={() => console.log(crm.id)}
            >
              <EditIcon />
            </Link>

            <Icon onClick={() => deletarCRM(crm)} style={{ color: 'black', cursor: 'pointer' }}>
              delete
            </Icon>
          </div>
        );
      },
    },
  ];

  const handleRowClick: GridEventListener<'rowClick'> = (params: any, event: any) => {
    if (event.target.ariaColIndex === null || Number(event.target.ariaColIndex) === columns.length)
      event.stopPropagation();
    else history.push('/crm/contatos/' + params.id);
  };

  return (
    <>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
          }}
        >
          <IconTituloStato>person</IconTituloStato>
          <Typography variant="h5">Contatos</Typography>
        </Grid>
        <Grid
          item
          xs={8}
          md={8}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
          }}
        >
          <ButtonStato
            onClick={() => history.push('/crm/programas/novo')}
            type="submit"
            variant="contained"
          >
            <Icon>person_add</Icon>
          </ButtonStato>
        </Grid>
      </GridTopPage>

      <Grid
        item
        xs={12}
        md={12}
        sx={{ alignContent: 'center', alignItems: 'center' }}
        style={{ padding: '0px 30px' }}
      >
        <Box
          sx={{
            height: '70vh',
            width: '100%',
          }}
        >
          <DataGrid
            getRowId={(row) => row?.id}
            rows={contatos}
            onRowClick={handleRowClick}
            columns={columns}
            loading={loading}
          />
        </Box>
      </Grid>
    </>
  );
};

export default withRouter(Contatos);
