import { FormControl, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { styled } from 'styled-components';
import { TextFieldStato } from '../../../../commons/styleds';
import {
    DesktopDatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const ConteudoStepper2: React.FC = () => {
    const methods = useFormContext();

    return (
        <>
            <Controller
                control={methods.control}
                name='origem'
                render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                        <FormInput
                            {...field}
                            inputRef={ref}
                            value={field.value}
                            label='Origem'
                        />
                    </FormControl>
                )}
            />

            <Grid item xs={12} md={4} sx={{ paddingTop: "20px" }}></Grid>

            <Controller
                control={methods.control}
                name='dtPublicacao'
                render={({ field }) => (
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        localeText={{
                            clearButtonLabel: 'Empty',
                            todayButtonLabel: 'Now',
                        }}
                    >
                        <DesktopDatePicker
                            {...field}
                            label='Data Publicação'
                            format='DD/MM/YYYY'
                            slotProps={{
                                textField: {
                                    required: true,
                                },
                            }}
                        />
                    </LocalizationProvider>
                )}
            />

            <Controller
                control={methods.control}
                name='ordem'
                render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                        <FormInput
                            {...field}
                            type='number'
                            inputRef={ref}
                            value={field.value}
                            label='Posição'
                        />
                    </FormControl>
                )}
            />

            <Controller
                control={methods.control}
                name='bu'
                render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant='outlined'>
                        <FormInput
                            {...field}
                            inputRef={ref}
                            value={field.value}
                            label='BU'
                        />
                    </FormControl>
                )}
            />
        </>
    );
};

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

export default ConteudoStepper2;
