import {
    Box,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    ThemeProvider,
    Tooltip,
    createTheme,
} from '@mui/material';
import { ptBR } from '@mui/material/locale';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import styled from 'styled-components';
import { ButtonStato, GridContainerCenter, SelectStato } from '../../../../../commons/styleds';
import { IComanda, IComandaEtapaReunioes, IEtapa } from '../../../../../commons/genericTypes';
import { toast } from 'react-toastify';
import { useEtapaActions } from '../../../../../states/config/Programas/etapas/etapa.actions';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';


interface IDrawerEtapas {
    open: boolean;
    loading: boolean;
    onClose: Function;
    handleClick: Function;
    onSave: Function;
    etapasComanda: IEtapa[];
    comandaEtapaReunioes: IComandaEtapaReunioes[];
}

const DrawerEtapas: React.FC<IDrawerEtapas> = (props) => {
    const { open, loading, onClose, handleClick, onSave, etapasComanda, comandaEtapaReunioes } = props;
    const [etapas, setEtapas] = useState<any[]>([]);
    const [etapasList, setEtapasList] = useState<any[]>([]);
    const [etapasComandaAtual, setEtapasComandaAtual] = useState<IEtapa[]>([]);
    const etapasActions = useEtapaActions();
    const [etapaId, setEtapaId] = useState<number>();
    const [loadEtapas, setLoadEtapas] = useState<boolean>();


    useEffect(() => {
        etapasActions
            .list()
            .then((resp: IEtapa[]) => {
                setEtapas(resp);
                setEtapasList(resp.filter(f => !(etapasComanda.findIndex(x => x.id == f.id) >= 0)))
            })
            .catch((err: any) => toast.warn(err));

        setEtapasComandaAtual(etapasComanda);

    }, []);

    const columns: GridColDef[] = [
        {
            field: 'nome',
            headerName: 'Etapa',
            width: 300,
            editable: false,
        },
        {
            field: 'Ação',
            headerName: 'Ação',
            width: 60,
            editable: false,
            renderCell: (params: GridCellParams) => {
                let t = params.row as IEtapa;
                return (
                    <>
                        {
                            handleTemAgendamento(t.id!) ?
                                <Tooltip title="Essa Etapa já tem um agendamento vinculado !">
                                    <IconButton
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {' '}
                                        <DeleteIcon sx={{
                                            opacity: "0.4"
                                        }} />{' '}
                                    </IconButton>
                                </Tooltip>
                                :
                                <IconButton
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => handleDelete(t.id!)}
                                >
                                    {' '}
                                    <DeleteIcon />{' '}
                                </IconButton>
                        }
                    </>
                );
            },
        },
    ]

    const handleClickAdd = () => {
        const etapaJaExiste = etapasComandaAtual.find((f)=> f.id == etapaId);
        if(etapaJaExiste != undefined){
            toast.warn('Essa Etapa já foi adicionada na lista !');
            return;
        }

        setLoadEtapas(true);
        setEtapasComandaAtual(etapasComandaAtual.concat(etapas.find((f) => f.id == etapaId)));
        setEtapasList([]);
        setEtapaId(undefined);
        setTimeout(function () {
            setEtapasList(etapas.filter(f => !(etapasComandaAtual.findIndex(x => x.id == f.id) >= 0)));
            setLoadEtapas(false)
        }, 500)
    };

    const Salvar = () => {
        onSave(etapasComandaAtual)
    };

    const handleTemAgendamento = (id: number) => {
        return comandaEtapaReunioes.find((f) => f.tbEtapaId == id);
    }

    const handleDelete = (id: number) => {

        const etapasAgendada = comandaEtapaReunioes.find((f) => f.tbEtapaId == id);
        if (etapasAgendada != null) {
            toast.warn('Essa Etapa já tem um agendamento vinculado !');
            return;
        }

        setEtapasComandaAtual(etapasComandaAtual.filter((f) => f.id != id))

    };

    const handleChange = (event: any) => {
        setEtapaId(event.target.value as number);
    };

    return (
        <Drawer
            style={{ minWidth: '500px' }}
            anchor={'right'}
            open={open}
            onClose={() => onClose(false)}
        >
            <>
                <Grid item
                    sx={{ minWidth: '500px', padding: "20px" }}
                >

                    <Grid sx={{
                        marginTop: "50px",
                        padding: "20px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        fontSize: "x-large"
                    }}>
                        Adicionar ou Remover Etapas
                    </Grid>

                    <GridItemCadastro item xs={12} md={12} sx={{
                        marginTop: '20px',
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <GridItemCadastro item xs={12} md={10} sx={{ marginTop: '20px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Etapas</InputLabel>
                                {!loadEtapas &&
                                    <SelectStato label="Etapas" value={etapaId} onChange={handleChange}>
                                        {etapasList &&
                                            etapasList.map((etapa: IEtapa, index: number) => (
                                                <MenuItem value={etapa.id} key={index}>
                                                    {etapa.nome}
                                                </MenuItem>
                                            ))}
                                    </SelectStato>
                                }
                            </FormControl>
                        </GridItemCadastro>

                        <GridItemCadastro item xs={12} md={2}>
                            <FormControl
                                fullWidth
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignContent: 'center',
                                    flexDirection: 'row-reverse',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <ButtonAdd onClick={handleClickAdd} variant="contained">
                                    +
                                </ButtonAdd>
                            </FormControl>
                        </GridItemCadastro>
                    </GridItemCadastro>

                    <Grid item xs={12}>
                        <Box
                            sx={{
                                height: '30vh',
                                width: '100%',
                            }}
                        >
                            <ThemeProvider theme={createTheme({}, ptBR)}>
                                <DataGrid
                                    getRowId={(row) => row?.id}
                                    rows={etapasComandaAtual ?? []}
                                    columns={columns}
                                    hideFooter={true}
                                    sx={{
                                        borderRadius: '20px',
                                    }}
                                />
                            </ThemeProvider>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{
                        paddingTop: "20px"
                    }}>
                        <ButtonStato variant="contained" onClick={Salvar} disabled={loading}>
                            Salvar
                        </ButtonStato>
                    </Grid>
                </Grid>
            </>
        </Drawer >
    );
};

export default DrawerEtapas;

const GridCadastro = styled(GridContainerCenter)`
  width: 97% !important;
  margin-right: 0px !important;
  padding: 15px 15px;
`;

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

const ButtonAdd = styled(ButtonStato)`
  min-width: 11px !important;
  margin-top: 15px !important;
`;
