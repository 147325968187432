import { useAxiosWrapper } from '../../services/axiosWrapper';

function useReunioesInternasActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/consultores`;
  const axiosWrapper = useAxiosWrapper();

  return {
    list,
    revisar,
  };

  async function list(idConsultor: number, query = '') {
    let url = baseUrl;
    url = `${url}/${idConsultor}/reunioesInternas`;
    if (query !== '') url += query;
    const recursos = await axiosWrapper.get(url);
    return recursos;
  }

  async function revisar(idConsultor: number, id: string | number, obj: object | any) {
    const recurso = await axiosWrapper.put(
      `${baseUrl}/${idConsultor}/reunioesInternas/${id}/revisar`,
      obj,
    );
    return recurso;
  }
}

export { useReunioesInternasActions };
