import { useSetRecoilState } from 'recoil';
import { authAtom } from './AuthState';
import { useAxiosWrapper } from '../../../../services/axiosWrapper';
import { history } from '../../../../helpers/history';
import { IUsuario } from '../../../../commons/genericTypes';

function useUsuarioActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/usuarios`;
  const axiosWrapper = useAxiosWrapper();
  const setAuth = useSetRecoilState(authAtom);

  return {
    login,
    logout,
    criarUsuario,
    buscarUsuarios,
    salvarUsuario,
    deletarUsuario,
    buscarUsuarioPorId
  };

  async function login(usuario: string, senha: string) {
    try {
      const user = await axiosWrapper.post(`${baseUrl}/autenticacao`, {
        usuario,
        senha,
      });
      localStorage.setItem('usuario', JSON.stringify(user));
      setAuth(user);
    } catch (e: any) { }

    const { from } = (history?.location?.state as any) || {
      from: { pathname: '/login' },
    };
    history.push(from);
  }

  function logout() {
    setAuth(null);
    localStorage.removeItem('usuario');
    history.push('/login');
  }

  async function criarUsuario(usuario: IUsuario) {
    try {
      const user = await axiosWrapper.post(`${baseUrl}/`, usuario);
      return user;
    } catch (e: any) { throw e; }
  }
  
  async function buscarUsuarios(apenasConsultores = false, idGrupo = 0) {
    try {
      const usuarios = await axiosWrapper.get(`${baseUrl}?apenasConsultores=${apenasConsultores}&idGrupo=${idGrupo}`, {
      });
      return usuarios;
    } catch (e: any) {
      return [];
    }
  }

  async function salvarUsuario(usuario: IUsuario) {
    try {
      const user = await axiosWrapper.put(`${baseUrl}/` + usuario.id, usuario);
      return user;
    } catch (e: any) { }
  }

  async function deletarUsuario(id: number) {
    try {
      const usuarios = await axiosWrapper.delete(`${baseUrl}/` + id, {
      });
      return usuarios;
    } catch (e: any) { }
  }

  async function buscarUsuarioPorId(id: number) {
    try {
      const usuarios = await axiosWrapper.get(`${baseUrl}/` + id, {
      });
      return usuarios;
    } catch (e: any) { }
  }
}

export { useUsuarioActions };
