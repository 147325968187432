import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Paper } from '@mui/material';
import HeaderPages from '../../../components/Generics/HeaderPages';
import { useEffect, useState } from 'react';
import {
  IComandaEtapaReunioes,
  IConsultor,
  IReuniaoInterna,
  IStatus,
} from '../../../commons/genericTypes';
import { toast } from 'react-toastify';
import { ButtonStato, SelectStato, TextFieldStato } from '../../../commons/styleds';
import styled from 'styled-components';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useStatusActions } from '../../../states/config/Programas/status/status.actions';
import { useTimesheetConsultorActions } from '../../../states/relatorios/timesheetConsultor/timesheetConsultor.actions';
import HistoricoTimesheetConsultor from './Historico';
import { useConsultorActions } from '../../../states/config/CadastroUsuarios/consultores/consultor.actions';
import { useComandaEtapaReuniaoActions } from '../../../states/config/Programas/comandaEtapaReunioes/comandaEtapaReuniao.actions';
import { StatusReuniaoEnum } from '../../../commons/enums';
import TimesheetRestultado from './TimesheetResultado';
import dayjs from 'dayjs';
import { useReunioesInternasActions } from '../../../states/reunioesInternas/reunioesInternas.actions';

interface IConsultorSelect {
  label: string;
  value: number;
}

enum periodoEnum {
  MES_ATUAL = 'Mês Atual',
  MES_PASSADO = 'Mês Passado',
  MES_RETRASADO = 'Mês Retrasado',
  RANGE_DATAS = 'Range de Datas',
}

interface IRelatorioTimeSheet {
  consultorId?: number;
  dataInicio?: Date;
  dataFim?: Date;
}

const RelatorioTimeSheet: React.FC = () => {
  const statusActions = useStatusActions();
  const consultorActions = useConsultorActions();
  const reunioesInternasActions = useReunioesInternasActions();
  const timesheetConsultorActions = useTimesheetConsultorActions();
  const comandaEtapaReunioesActions = useComandaEtapaReuniaoActions();
  const [loading, setLoading] = useState(false);
  const [loadingRelatorio, setLoadingRelatorio] = useState(false);
  const [selectConsultores, setSelectConsultores] = useState<IConsultorSelect[]>([]);
  const [consultorId, setConsultorId] = useState<number>();
  const [consultorNome, setConsultorNome] = useState<string>('');
  const [dataInicio, setDataInicio] = useState<Date>();
  const [dataFim, setDataFim] = useState<Date>();
  const [periodo, setPeriodo] = useState<string>('RANGE_DATAS');
  const [statusComanda, setStatusComanda] = useState<number>();
  const [status, setStatus] = useState<IStatus[]>([]);
  const [statusNome, setStatusNome] = useState<string>('');
  const [comandaEtapasReunioes, setComandaEtapasReunioes] = useState<IComandaEtapaReunioes[]>([]);
  const [reunioesInternas, setReunioesInternas] = useState<IReuniaoInterna[]>([]);

  const getStatusDb = () => {
    statusActions
      .list()
      .then((resp) => {
        const statusValidos = ['Ativo', 'Recolocado', 'Extensão'];
        const filtered = resp.filter((s: IStatus) => statusValidos.includes(s.nome));
        setStatus(resp);
      })
      .catch((err) => toast.warn(err));
  };

  useEffect(() => {
    getStatusDb();

    consultorActions
      .list()
      .then((resp: any) => {
        setSelectConsultores(
          resp.resultado.map((c: IConsultor) => {
            return { label: c.nome, value: c.id };
          }),
        );
      })
      .catch((err: any) => toast.warn(err));
  }, []);

  const onChangePeriodo = (valor: string) => {
    setPeriodo(valor);

    if (valor === 'RANGE_DATAS') {
      setDataInicio(undefined);
      setDataFim(undefined);
      return;
    }

    const now = new Date();
    switch (valor) {
      case 'MES_ATUAL':
        setDataInicio(new Date(now.getFullYear(), now.getMonth(), 1));
        setDataFim(new Date(now.getFullYear(), now.getMonth() + 1, 0));
        break;
      case 'MES_PASSADO':
        setDataInicio(new Date(now.getFullYear(), now.getMonth() - 1, 1));
        setDataFim(new Date(now.getFullYear(), now.getMonth(), 0));
        break;
      case 'MES_RETRASADO':
        setDataInicio(new Date(now.getFullYear(), now.getMonth() - 2, 1));
        setDataFim(new Date(now.getFullYear(), now.getMonth() - 1, 0));
        break;
      default:
        break;
    }
  };

  const validaPodeEnviar = () => {
    if (!consultorId) {
      toast.warn('Consultor é obrigatório');
      return false;
    }

    if (periodo === 'RANGE_DATAS' && (!dataInicio || !dataFim)) {
      toast.warn('É necessário preencher as duas datas');
      return false;
    }

    return true;
  };

  const getDataBusca = () => {
    const consultor = selectConsultores.filter((c) => c.value === consultorId)[0].label;
    setConsultorNome(consultor);

    const statusComandaNome = status.filter((s) => s.id === statusComanda)[0]?.nome;
    setStatusNome(statusComandaNome);

    const data = {
      consultorId,
      idConsultor: consultorId,
      consultorNome: consultor,
      dataInicio: dataInicio?.toISOString(),
      dataFim: dataFim?.toISOString(),
      statusComanda,
      statusComandaNome,
    };

    return data;
  };

  const filtrarRelatorio = (e: any) => {
    e.preventDefault();
    if (!validaPodeEnviar()) return;

    setLoading(true);

    const data = getDataBusca() as any;
    data.statusReuniao = StatusReuniaoEnum.APROVADA;
    const filteredData = Object.entries(data)
      .filter(([_, value]) => value !== null && value !== undefined)
      .reduce(
        (acc, [key, value]) => {
          acc[key] = value!.toString();
          return acc;
        },
        {} as Record<string, string>,
      );
    const queryString = new URLSearchParams(filteredData).toString();

    comandaEtapaReunioesActions
      .list(false, `?${queryString}`)
      .then((resp: any) => setComandaEtapasReunioes(resp))
      .catch((e) => toast.error(e))
      .finally(() => setLoading(false));

    reunioesInternasActions
      .list(data.idConsultor, `?${queryString}`)
      .then((resp: any) => setReunioesInternas(resp))
      .catch((e) => toast.error(e));
  };

  const gerarRelatorio = (e: any) => {
    e.preventDefault();
    if (!validaPodeEnviar()) return;
    setLoadingRelatorio(true);

    const data = getDataBusca();

    timesheetConsultorActions
      .create(data)
      .then((resp: any) => {
        toast.success('Relatorio gerado');
        window.open(resp.linkDownload, '_blank', 'noopener,noreferrer');
      })
      .catch((e) => toast.error(e))
      .finally(() => {
        setLoading(false);
        setLoadingRelatorio(false);
      });
  };

  return (
    <>
      <HeaderPages
        actionBack={true}
        arrowBackClick="/relatorios"
        icon="contact_emergency_rounded"
        title="Time Sheet Consultor"
      />

      <DivConteudo>
        <Grid item xs={12}>
          <h2>Preencha as informações para extrair o relatório:</h2>
        </Grid>

        <form>
          <PaperRelatorio elevation={3}>
            <GridTimeSheet container sx={{ width: '85%' }} spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={selectConsultores}
                  onChange={(event: any, newValue: IConsultorSelect | null) => {
                    setConsultorId(newValue?.value);
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params: any) => (
                    <TextFieldStato required {...params} label="Consultor" />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status da comanda</InputLabel>
                  <SelectStato
                    value={statusComanda}
                    onChange={(event) => setStatusComanda(event.target.value as number)}
                    required
                    label="Status da comanda"
                  >
                    {status.map((statu: IStatus, index: number) => (
                      <MenuItem value={statu.id} key={index}>
                        {statu.nome}
                      </MenuItem>
                    ))}
                  </SelectStato>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Período</InputLabel>
                  <SelectStato
                    value={periodo}
                    onChange={(e) => onChangePeriodo(e.target.value as string)}
                    required
                    label="Período"
                  >
                    {Object.keys(periodoEnum).map((periodo: string, index: number) => (
                      <MenuItem value={periodo} key={index}>
                        {periodoEnum[periodo as keyof typeof periodoEnum]}
                      </MenuItem>
                    ))}
                  </SelectStato>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                {periodo === 'RANGE_DATAS' && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{
                      clearButtonLabel: 'Empty',
                      todayButtonLabel: 'Now',
                    }}
                  >
                    <DesktopDatePicker
                      value={dayjs(dataInicio)}
                      onChange={(value) => setDataInicio(value!.toDate() as Date)}
                      label="Data Início"
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                {periodo === 'RANGE_DATAS' && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{
                      clearButtonLabel: 'Empty',
                      todayButtonLabel: 'Now',
                    }}
                  >
                    <DesktopDatePicker
                      value={dayjs(dataFim)}
                      onChange={(value) => setDataFim(value!.toDate() as Date)}
                      label="Data Fim"
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              </Grid>

              <Grid item sx={{ display: 'flex', width: '100%' }}>
                <Grid sx={{ marginRight: '15px' }}>
                  <ButtonStato type="submit" variant="outlined" onClick={filtrarRelatorio}>
                    Filtrar
                  </ButtonStato>
                </Grid>
                <Grid>
                  <ButtonStato
                    type="submit"
                    variant="contained"
                    onClick={gerarRelatorio}
                    disabled={loadingRelatorio}
                  >
                    Extrair Relatório
                  </ButtonStato>
                </Grid>
              </Grid>
            </GridTimeSheet>
          </PaperRelatorio>
        </form>

        {comandaEtapasReunioes.length > 0 || reunioesInternas.length > 0 ? (
          <TimesheetRestultado
            consultor={consultorNome}
            dataInicio={dataInicio!}
            dataFim={dataFim!}
            status={statusNome}
            resultado={comandaEtapasReunioes}
            reunioesInternas={reunioesInternas}
          />
        ) : (
          <HistoricoTimesheetConsultor />
        )}
      </DivConteudo>
    </>
  );
};

const GridTimeSheet = styled(Grid)`
  padding: 20px 0 0 40px;
  min-height: 200px;
  display: flex;
  align-items: center;
`;

const DivConteudo = styled.div`
  max-width: 85vw;
  margin: 0 auto;
  padding-left: 50px;
`;

export const PaperRelatorio = styled(Paper)`
  padding: 20px;
  border-radius: 40px !important;
  width: 85%;
  min-height: 200px;
`;

export default RelatorioTimeSheet;
