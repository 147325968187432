import {
  Autocomplete,
  Box,
  Checkbox,
  createTheme,
  Divider,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IConfigSegmento, ISetor, IUsuario } from '../../../../commons/genericTypes';
import {
  EmpresaStatusEnum,
  IEmpresaRelacionamento,
  INivelRelacionamento,
  IVaga,
} from '../../../../commons/genericTypes/mercadoAlvo';
import {
  ButtonStato,
  GridTopPage,
  IconTituloStato,
  SelectStato,
  TextFieldStato,
} from '../../../../commons/styleds';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { history } from '../../../../helpers/history';
import { useUsuarioActions } from '../../../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { useConfigSegmentoActions } from '../../../../states/config/ConfiguracaoSistema/segmentos/configSegmento.actions';
import { useSetorActions } from '../../../../states/config/ConfiguracaoSistema/setores/setor.actions';
import { useEmpresaRelacionamentoActions } from '../../../../states/mesaDeMercado/empresaRelacionamento/empresaRelacionamento.actions';
import { useNivelRelacionamentoActions } from '../../../../states/mesaDeMercado/nivelRelacionamento/nivelRelacionamento.actions';
import { GridItemCadastro } from '../../../CRM/Comanda';
import { BasePage, Container } from '../../../CRM/Empresa';
import { IParams } from '../../../CRM/MiniBio/Endereco';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useVagaActions } from '../../../../states/mesaDeMercado/vagas/vaga.actions';
import { createColumns } from '../../FollowUp/columns';
import { DataGrid } from '@mui/x-data-grid';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { ptBR } from '@mui/material/locale';
import dayjs from 'dayjs';

const empty: IEmpresaRelacionamento = {
  empresa: '',
  contato: '',
  status: undefined,
  ultimoContato: undefined,
  idAgenteDeMercado: undefined,
  idNivelRelacionamento: undefined,
  idSegmento: undefined,
  setores: undefined,
};

const EmpresaRelacionamento: React.FC = () => {
  const { id } = useParams<IParams>();
  const novoCadastro = id === 'nova';
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const actions = useEmpresaRelacionamentoActions();
  const vagasActions = useVagaActions();
  const setorActions = useSetorActions();
  const usuarioActions = useUsuarioActions();
  const segmentoActions = useConfigSegmentoActions();
  const nivelRelacionamentoActions = useNivelRelacionamentoActions();
  const [vagas, setVagas] = useState<IVaga[]>([]);
  const [niveis, setNiveis] = useState<INivelRelacionamento[]>([]);
  const [setores, setSetores] = useState<ISetor[]>([]);
  const [segmentos, setSegmentos] = useState<IConfigSegmento[]>([]);
  const [agentesMercado, setAgentesMercado] = useState<IUsuario[]>([]);

  const getNiveis = () => {
    nivelRelacionamentoActions
      .list()
      .then((data) => setNiveis(data))
      .catch((error) => toast.error(error));
  };

  const getSetores = () => {
    setorActions
      .list()
      .then((data) => setSetores(data))
      .catch((error) => toast.error(error));
  };

  const getAgentesMercado = () => {
    usuarioActions
      .buscarUsuarios()
      .then((data) => setAgentesMercado(data.resultado))
      .catch((error) => toast.error(error));
  };

  const getSegmentos = () => {
    segmentoActions
      .list()
      .then((data) => setSegmentos(data))
      .catch((error) => toast.error(error));
  };

  const getVagas = () => {
    vagasActions
      .list(false, `?pagina=1&tamanhoPagina=1000&idEmpresa=${id}`)
      .then((data) => {
        setVagas(data.itens);
      })
      .catch((e) => toast.error(e));
  };

  const getEmpresaRelacionada = () => {
    actions.get(Number(id)).then((data) => {
      methods.reset(data, { keepDefaultValues: true });
      getInfos();
      getVagas();
    });
  };

  const getInfos = () => {
    getNiveis();
    getSetores();
    getSegmentos();
    getAgentesMercado();
  };

  useEffect(() => {
    if (!novoCadastro) getEmpresaRelacionada();
    else getInfos();
  }, [id]);

  const onSubmit = async (data: IEmpresaRelacionamento) => {
    try {
      if (novoCadastro) await actions.create(data);
      else await actions.update(data.id!, data);
      toast.success('Salvo com sucesso!');
      history.goBack();
    } catch (error: any) {
      toast.error(error);
    }
  };

  const columns = createColumns({
    canExclude: false,
    handleEditar: (obj: any) => {
      toast.warn('Funcionalidade em desenvolvimento');
    },
    handleDeletar: (obj: any) => {
      toast.warn('Funcionalidade em desenvolvimento');
    },
  });

  return (
    <BasePage>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={8}
          md={8}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <IconTituloStato>store</IconTituloStato>
          <Typography variant="h5">Empresas Relacionamento</Typography>
        </Grid>

        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
          }}
        >
          <ButtonStato onClick={() => history.goBack()} type="submit" variant="contained">
            <Icon>undo</Icon>
            Voltar
          </ButtonStato>
        </Grid>
      </GridTopPage>

      <Container container rowSpacing={2} style={{ paddingTop: '10px' }}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {novoCadastro ? 'Cadastrar nova Empresa' : 'Editar Empresa'}
          </Typography>
          <Divider sx={{ maxWidth: '40%' }} />
        </Grid>
        <Grid item xs={12}>
          <FormProvider {...methods}>
            <form id="formEmpresa" onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container gap={2} id="form-cadastro-empresa">
                <GridItemCadastro item xs={12} md={5}>
                  <Controller
                    control={methods.control}
                    name="empresa"
                    render={({ field }) => (
                      <FormControl required fullWidth variant="outlined">
                        <TextFieldStato {...field} label="Empresa" required />
                      </FormControl>
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastro item xs={12} md={5}>
                  <Controller
                    control={methods.control}
                    name="contato"
                    render={({ field }) => (
                      <FormControl fullWidth variant="outlined">
                        <TextFieldStato
                          {...field}
                          label="Contato"
                          slotProps={{
                            htmlInput: { maxLength: 50 }
                          }}
                        />
                      </FormControl>
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastro item xs={12} md={5}>
                  <Controller
                    control={methods.control}
                    name="idAgenteDeMercado"
                    render={({ field }) => (
                      <FormControl fullWidth>
                        {agentesMercado && agentesMercado.length > 0 && (
                          <>
                            <InputLabel id="agente-de-mercado-label">Agente de Mercado</InputLabel>
                            <SelectStato
                              {...field}
                              value={field.value}
                              required
                              label="Agente de Mercado"
                            >
                              {agentesMercado?.map((agente: IUsuario, index: number) => (
                                <MenuItem value={agente.id} key={index}>
                                  {agente.nome}
                                </MenuItem>
                              ))}
                            </SelectStato>
                          </>
                        )}
                      </FormControl>
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastro item xs={12} md={5}>
                  <Controller
                    control={methods.control}
                    name="idNivelRelacionamento"
                    render={({ field }) => (
                      <FormControl fullWidth>
                        {niveis && niveis.length > 0 && (
                          <>
                            <InputLabel id="nivel-relacionamento-label">
                              Nível de Relacionamento
                            </InputLabel>
                            <SelectStato {...field} required label="Nível de relacionamento">
                              {niveis?.map((nivel: INivelRelacionamento, index: number) => (
                                <MenuItem value={nivel.id} key={index}>
                                  {nivel.nome}
                                </MenuItem>
                              ))}
                            </SelectStato>
                          </>
                        )}
                      </FormControl>
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastro item xs={12} md={5}>
                  <Controller
                    control={methods.control}
                    name="idSegmento"
                    render={({ field }) => (
                      <FormControl fullWidth>
                        {segmentos && segmentos.length > 0 && (
                          <>
                            <InputLabel id="segmento-label">Segmento</InputLabel>
                            <SelectStato {...field} required label="Segmento">
                              {segmentos?.map((segmento: IConfigSegmento, index: number) => (
                                <MenuItem value={segmento.id} key={index}>
                                  {segmento.descricao}
                                </MenuItem>
                              ))}
                            </SelectStato>
                          </>
                        )}
                      </FormControl>
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastro item xs={12} md={5}>
                  <Controller
                    name="status"
                    control={methods.control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="status-label">Status</InputLabel>
                        <SelectStato {...field} required label="Status">
                          {Object.values(EmpresaStatusEnum)?.map(
                            (status: string, index: number) => (
                              <MenuItem value={status} key={index}>
                                {status}
                              </MenuItem>
                            ),
                          )}
                        </SelectStato>
                      </FormControl>
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastro item xs={12} md={10}>
                  <Controller
                    name="setores"
                    control={methods.control}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        options={setores}
                        fullWidth
                        disableCloseOnSelect
                        getOptionLabel={(option: ISetor) => option.descricao!}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.descricao}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Setores" placeholder="Escolha..." />
                        )}
                        {...field}
                        onChange={(_, data) => field.onChange(data.map((d) => ({ idSetor: d.id })))}
                      />
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastro item xs={12} md={5}>
                  <Controller
                    control={methods.control}
                    name="ultimoContato"
                    render={({ field }) => (
                      <FormControl fullWidth variant="outlined">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            {...field}
                            value={field.value ? dayjs(field.value) : null}
                            label="Último Contato"
                            format="DD/MM/YYYY"
                            disabled
                          />
                        </LocalizationProvider>
                      </FormControl>
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastro
                  item
                  xs={12}
                  md={5}
                  sx={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}
                >
                  <ButtonStato type="submit" variant="contained" sx={{ maxHeight: '35px' }}>
                    Salvar
                  </ButtonStato>
                </GridItemCadastro>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Container>

      <Divider sx={{ marginTop: '20px' }} />

      <Container sx={{ paddingTop: '10px' }}>
        {!novoCadastro && (
          <>
            <Typography variant="h5">Vagas</Typography>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: '60vh',
                  width: '100%',
                  marginTop: '20px',
                }}
              >
                <ThemeProvider theme={createTheme({}, ptBR)}>
                  <DataGrid
                    getRowId={(row) => row?.id!}
                    rows={vagas}
                    columns={columns}
                    pagination
                    loading={!vagas}
                    sx={{
                      backgroundColor: 'white',
                      border: '1px solid black',
                      borderRadius: '20px',
                    }}
                  />
                </ThemeProvider>
              </Box>
            </Grid>
          </>
        )}
      </Container>
    </BasePage>
  );
};

export default EmpresaRelacionamento;
